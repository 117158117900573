<template>
  <!-- Single Popular Course -->
  <div>
    <div
      v-if="
        list.courses !== undefined &&
        list.courses.length > 0 &&
        list.types != 'teacher'
      "
      class="row"
    >
      <div
        class="col-12 col-md-6 col-lg-3"
        v-for="(li, i) in list.courses"
        :key="i"
        @click="goTo(li, list.types)"
      >
        <div
          class="single-popular-course mb-50 wow fadeInUp"
          data-wow-delay="250ms"
        >
          <img v-lazy="li.CoverImg" alt="" />
          <!-- Course Content -->
          <div class="course-content">
            <h4 class="ovdecp">{{ li.Name }}</h4>
            <div class="meta d-flex align-items-center">
              <a href="#">资深讲师</a>
              <span><i class="fa fa-circle" aria-hidden="true"></i></span>
              <a href="#">{{ li.TeacherNames }}</a>
            </div>
          </div>
          <!-- Seat Rating Fee -->
          <div class="seat-rating-fee d-flex justify-content-between">
            <div class="seat-rating h-50 d-flex align-items-center">
              <div class="seat">
                <i class="fa fa-user" aria-hidden="true"></i>
                {{ li.CourseStudentCount }}
              </div>
              <div class="rating" v-if="li.CoursePackage != 1">
                <i
                  class="fa"
                  :class="
                    li.CourseType === 1 ? 'fa-youtube-play' : 'fa-video-camera'
                  "
                  aria-hidden="true"
                ></i>
                <span
                  >&nbsp;&nbsp;{{
                    li.CourseType === 1 ? "点播课" : "直播课"
                  }}</span
                >
              </div>
              <div class="rating" v-else>
                <i class="fa fa-folder-open" aria-hidden="true"></i>
                <span>&nbsp;&nbsp;课程包</span>
              </div>
            </div>
            <div class="course-fee h-100">
              <a
                href="#"
                class="free"
                :class="
                  li.IsCharge === true && li.OriginalPrice != null
                    ? 'bgc_e3d21b'
                    : 'bgc_3ab57f'
                "
              >
                <span v-if="li.IsCharge === true && li.OriginalPrice != null">
                  ￥{{ li.OriginalPrice | priceToFixed }}
                </span>
                <span v-else>免费</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      style="overflow: hidden"
      v-else-if="
        list.courses !== undefined &&
        list.courses.length > 0 &&
        list.types == 'teacher'
      "
      @click="handleClick($event)"
    >
      <vue-seamless
        :data="list.courses"
        :class-option="optionLeft"
        class="seamless-warp"
      >
        <!-- :style="[{ width: teacherWidth + 'px' }]" -->
        <ul class="ul-item">
          <li
            class="li-item"
            v-for="(item, index) in list.courses"
            :key="index"
          >
            <img
              :src="item.HeadImg"
              alt=""
              width="165"
              height="165"
              :data-obj="JSON.stringify(item)"
            />
          </li>
        </ul>
        <!-- <div class="teacher_row">
          <div class="teacher_col" v-for="(li, j) in list.courses" :key="j">
            <div class="bgc_ffffff"> -->
        <!--@click="goTo(li, list.types)"-->
        <!-- <div class="coverImg coverImg1">
                <img v-lazy="li.HeadImg" alt=""  :data-obj="JSON.stringify(li)"/>
              </div> -->
        <!--<p class="til">{{ li.Name }}</p>
              <p class="shortTitle">
                <span>{{ li.ShortTitle }}{{ li.ShortTitle }}</span>
              </p>-->
        <!-- </div>
          </div>
        </div> -->
      </vue-seamless>
    </div>
  </div>
</template>
<script>
import vueSeamless from "vue-seamless-scroll";
export default {
  props: ["list"],
  data () {
    return {
      teacherWidth: 0,
      listData: [1, 2, 3, 4, 5, 6],
    };
  },
  created () {
    // console.log(this.list.courses);
  },
  computed: {
    optionLeft () {
      return {
        direction: 2,
        limitMoveNum: 5,
      };
    },
  },
  mounted () {
    if (this.list.types === "teacher") {
      console.log(this.teacherWidth);
      this.teacherWidth = 306 * this.list.courses.length * 2;
    }
  },
  components: {
    vueSeamless,
  },
  methods: {
    handleClick (event) {
      let obj = event.target.dataset.obj;
      localStorage.setItem("teacherInfo", obj);
      this.$router.push({ path: "/home/teacherDetails" });
    },
    goTo (obj, type) {
      console.log(obj, type);
      let ob = {
        Id: obj.Id,
        Name: obj.Name,
        CoursePackage: obj.CoursePackage,
      };
      if (type == "course") {
        this.$router.push({ path: "/course/details", query: ob });
      } else if (type == "teacher") {
        localStorage.setItem("teacherInfo", JSON.stringify(obj));
        this.$router.push({ path: "/home/teacherDetails" });
      } else if (type == "download") {
        this.$router.push({ path: "/dataDownload/list", query: ob });
      } else if (type == "question") {
        this.$router.push({ path: "/onlineQuestionBank/details", query: ob });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.seamless-warp {
  width: 165px * 8;
  height: 165px;
  margin: 0 auto;
  overflow: hidden;
  margin-bottom: 65px;
  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    &.ul-item {
      display: flex;
      .li-item {
        width: 165px;
        height: 165px;
        margin-right: 12px;
        line-height: 165px;
        color: #fff;
        text-align: center;
        overflow: hidden;
        border-radius: 6px;
        font-size: 30px;
        img {
          width: 165px;
          height: 165px;
          transition: 0.5s;
          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }
  }
  // overflow: hidden;
  // height: 442px;
  // margin-bottom: 50px;
  .teacher_row {
    margin: 40px 0 25px;
    .teacher_col {
      width: 165px;
      float: left;
      margin: 0 12px;
      min-height: 257px;
      margin-bottom: 25px;
      .coverImg {
        width: 165px;
        height: 165px;
        overflow: hidden;
        border-radius: 6px;
        img {
          width: 100%;
          height: 100%;
          transition: 0.5s;
          &:hover {
            transform: scale(1.2);
          }
        }
      }
      .til {
        font-size: 18px;
        padding: 10px 10px 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .shortTitle {
        padding: 0 10px 10px;
        font-size: 14px;
        color: #97a2a7;
        span {
          height: 40px;
          line-height: 150%;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}
.course-content {
  padding: 25px 25px 10px;
  .ovdecp {
    font-size: 17px;
  }
}
</style>
