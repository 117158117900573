var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.list.courses !== undefined &&
      _vm.list.courses.length > 0 &&
      _vm.list.types != 'teacher'
    )?_c('div',{staticClass:"row"},_vm._l((_vm.list.courses),function(li,i){return _c('div',{key:i,staticClass:"col-12 col-md-6 col-lg-3",on:{"click":function($event){return _vm.goTo(li, _vm.list.types)}}},[_c('div',{staticClass:"single-popular-course mb-50 wow fadeInUp",attrs:{"data-wow-delay":"250ms"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(li.CoverImg),expression:"li.CoverImg"}],attrs:{"alt":""}}),_c('div',{staticClass:"course-content"},[_c('h4',{staticClass:"ovdecp"},[_vm._v(_vm._s(li.Name))]),_c('div',{staticClass:"meta d-flex align-items-center"},[_c('a',{attrs:{"href":"#"}},[_vm._v("资深讲师")]),_vm._m(0,true),_c('a',{attrs:{"href":"#"}},[_vm._v(_vm._s(li.TeacherNames))])])]),_c('div',{staticClass:"seat-rating-fee d-flex justify-content-between"},[_c('div',{staticClass:"seat-rating h-50 d-flex align-items-center"},[_c('div',{staticClass:"seat"},[_c('i',{staticClass:"fa fa-user",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(li.CourseStudentCount)+" ")]),(li.CoursePackage != 1)?_c('div',{staticClass:"rating"},[_c('i',{staticClass:"fa",class:li.CourseType === 1 ? 'fa-youtube-play' : 'fa-video-camera',attrs:{"aria-hidden":"true"}}),_c('span',[_vm._v(" "+_vm._s(li.CourseType === 1 ? "点播课" : "直播课"))])]):_c('div',{staticClass:"rating"},[_c('i',{staticClass:"fa fa-folder-open",attrs:{"aria-hidden":"true"}}),_c('span',[_vm._v(" 课程包")])])]),_c('div',{staticClass:"course-fee h-100"},[_c('a',{staticClass:"free",class:li.IsCharge === true && li.OriginalPrice != null
                  ? 'bgc_e3d21b'
                  : 'bgc_3ab57f',attrs:{"href":"#"}},[(li.IsCharge === true && li.OriginalPrice != null)?_c('span',[_vm._v(" ￥"+_vm._s(_vm._f("priceToFixed")(li.OriginalPrice))+" ")]):_c('span',[_vm._v("免费")])])])])])])}),0):(
      _vm.list.courses !== undefined &&
      _vm.list.courses.length > 0 &&
      _vm.list.types == 'teacher'
    )?_c('div',{staticStyle:{"overflow":"hidden"},on:{"click":function($event){return _vm.handleClick($event)}}},[_c('vue-seamless',{staticClass:"seamless-warp",attrs:{"data":_vm.list.courses,"class-option":_vm.optionLeft}},[_c('ul',{staticClass:"ul-item"},_vm._l((_vm.list.courses),function(item,index){return _c('li',{key:index,staticClass:"li-item"},[_c('img',{attrs:{"src":item.HeadImg,"alt":"","width":"165","height":"165","data-obj":JSON.stringify(item)}})])}),0)])],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('i',{staticClass:"fa fa-circle",attrs:{"aria-hidden":"true"}})])}]

export { render, staticRenderFns }