<template>
  <div>
    <course :type="type"></course>
  </div>
</template>

<script>
import course from "@/components/course2";
export default {
  data () {
    return {
      type: "course",
    };
  },
  components: {
    course
  },
  created () { },
  mounted () {

  },
  methods: {

  },
};
</script>

<style lang="less" scoped>
</style>