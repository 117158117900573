<template>
  <div class="course">
    <div class="top">
      <div class="width_1200">
        <el-row>
          <el-col :span="1" class="c_999999">筛选：</el-col>
          <el-col :span="5">
            <el-input
              placeholder="搜索"
              class="search_input"
              v-model="search"
              @keyup.enter.native="searchClcik()"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="searchClcik()"
              ></el-button>
            </el-input>
          </el-col>
          <el-col :span="2" class="c_999999">考试方向：</el-col>
          <el-col :span="5">
            <el-popover placement="bottom" title="" trigger="hover" content="">
              <el-cascader-panel
                class=""
                v-model="dataTreeVal"
                :options="dataTree"
                :props="{
                  label: 'text',
                  value: 'id',
                  checkStrictly: true,
                  expandTrigger: 'hover',
                }"
                :show-all-levels="false"
                clearable
                @change="handleChange"
              ></el-cascader-panel>
              <div slot="reference" class="couser_cascader_hover">
                {{ treeVal }}
              </div>
            </el-popover>
          </el-col>
          <el-col :span="5">
            <el-select
              v-model="cTypesVal"
              placeholder="请选择课程类型"
              @change="courseTypesClcik"
            >
              <el-option
                v-for="(item, i) in courseTypes"
                :key="i"
                :label="item.lable"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="5">
            <el-select
              v-model="bTypesVal"
              placeholder="请选择收费类型"
              @change="bidTypesClcik"
            >
              <el-option
                v-for="(item, i) in bidTypes"
                :key="i"
                :label="item.lable"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="cont mt-40">
      <div class="width_1200">
        <!-- <home-list v-if="list.courses.length > 0" :list="list"></home-list> -->
        <div class="home_row">
          <div
            class="hover_shadow home_col"
            v-for="(item, index) in list.courses"
            :key="index"
            @click="goTo(item)"
          >
            <div class="img_dom">
              <img v-lazy="item.CoverImg" alt="" srcset="" />
              <div
                v-if="item.CoursePackage == 0"
                :class="
                  item.CourseType === 1
                    ? 'dbk'
                    : item.CourseType === 0
                    ? 'zbk'
                    : 'msk'
                "
              >
                {{
                  item.CourseType === 1
                    ? "点播课"
                    : item.CourseType === 0
                    ? "直播课"
                    : "面授课"
                }}
              </div>
              <div v-else class="kcb">课程包</div>
            </div>
            <div class="rig">
              <div class="title decimalPoint" :title="item.Name">
                {{ item.Name }}
              </div>
              <div
                v-if="item.TeacherInfo.length > 0"
                class="decimalPoint"
                style="margin: 18px 0"
              >
                <div class="per" style="width: 200%">
                  <div v-for="(it, i) in item.TeacherInfo.slice(0, 3)" :key="i">
                    <img v-lazy="it.HeadImg" alt="" srcset="" />
                    <span>{{ it.Name }}</span>
                  </div>
                </div>
              </div>
              <div
                class="price"
                v-if="item.IsCharge === true && item.OriginalPrice != null"
              >
                <div><span>￥</span>{{ item.OriginalPrice }}</div>
                <div v-if="item.ShowPrice">￥{{ item.ShowPrice }}</div>
              </div>
              <div class="price" v-else>免费</div>
            </div>
          </div>
        </div>
        <div v-if="list.courses.length <= 0">
          <no-data></no-data>
        </div>
      </div>
      <div class="width_1200">
        <el-pagination
          v-if="list.courses.length > 0"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          :page-size="pageSize"
          layout="prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { courseQueryCourseList } from "@/api/home";
import { courseQueryCourseCategoryTree } from "@/api/course";
// import courseList from "@/components/courseList";
import homeList from "@/components/homeList";
import noData from "@/components/noData";
export default {
  props: ["type"],
  data () {
    return {
      directions: ["不限", "艺术创作", "艺术高考", "艺术考博", "艺术考研"],
      courseTypes: [
        { id: 0, value: "", lable: "请选择课程类型" },
        { id: 1, value: "", lable: "不限" },
        { id: 2, value: "0", lable: "直播课程" },
        { id: 3, value: "1", lable: "点播课程" },
        { id: 4, value: "2", lable: "面授课程" },
      ],
      bidTypes: [
        { id: 0, value: "", lable: "请选择收费类型" },
        { id: 1, value: "", lable: "不限" },
        { id: 2, value: "0", lable: "免费" },
        { id: 3, value: "1", lable: "收费" },
      ],
      cTypesVal: "请选择课程类型",
      bTypesVal: "请选择收费类型",
      directionsIndex: 0,
      courseTypesIndex: 0, //0直播1点播
      bidTypesIndex: 0,
      IsCharge: "",
      search: "",
      list: {
        courses: [],
        type: ""
      },
      page: 1, //页码
      pageSize: 12, //每页条数
      total: 0,
      dataTree: [],
      dataTreeVal: "",
      dirIds: [],
      treeVal: '请选择',
      index: null,
    };
  },
  components: {
    homeList, noData
  },
  created () {
    this.index = this.$route.params.index
  },
  mounted () {
    this.postCourseQueryCourseList();
    this.getCourseQueryCourseCategoryTree();
  },
  methods: {
    goTo (obj, type) {
      let ob = {
        Id: obj.Id,
        Name: obj.Name,
        CoursePackage: obj.CoursePackage,
      };
      this.$router.push({ path: "/course/details", query: ob });
    },
    handleChange (e) {
      this.dirIds = [];
      if (e[0] !== "") {
        this.dirIds = e;
        // console.log(this.dirIds[this.dirIds.length - 1], '')
        this.dataTreeFor(this.dataTree, this.dirIds[this.dirIds.length - 1]);
      }
      this.postCourseQueryCourseList();
    },
    // 分类树形列表
    async getCourseQueryCourseCategoryTree () {
      const res = await courseQueryCourseCategoryTree();
      if (res.success == true) {
        this.dataTree = res.response;
        this.dataTree.unshift({
          id: '',
          text: '不限',
          parentId: 0,
          children: null,
          li_attr: null,
          type: null,
        })
      } else {
        this.$message.error(res.msg);
      }
    },
    dataTreeFor (obj, id) {
      // console.log(obj)
      obj.forEach((item) => {
        // console.log(item)
        // if (item.children == null) return false;
        if (item.id == id) {
          this.treeVal = item.text;
          // console.log(item)
        } else {
          if (item.children) this.dataTreeFor(item.children, id)
        }
      })
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.postCourseQueryCourseList();
    },
    //投标类型
    bidTypesClcik (e) {
      this.page = 1;
      this.bidTypesIndex = e;
      this.bTypesVal = this.bidTypes[this.bidTypesIndex].lable
      this.postCourseQueryCourseList();
    },
    //课程类型
    courseTypesClcik (e) {
      this.page = 1;
      this.courseTypesIndex = e;
      this.cTypesVal = this.courseTypes[this.courseTypesIndex].lable
      this.postCourseQueryCourseList();
    },
    //考试方向切换
    directionsClcik (e) {
      this.page = 1;
      this.directionsIndex = e;
      this.postCourseQueryCourseList();
    },
    //搜索课程
    searchClcik () {
      this.page = 1;
      this.postCourseQueryCourseList();
    },
    // 列表数据
    async postCourseQueryCourseList () {
      let parm = {}
      parm.pageIndex = this.page;
      parm.pageSize = this.pageSize;
      parm.name = this.search;
      //考试方向
      // if (this.directionsIndex != 0) {
      //   parm.courseDirection = this.directions[this.directionsIndex]
      // }
      if (this.dirIds.length > 0) {
        parm.categoryId = this.dirIds[this.dirIds.length - 1]
      }
      //课程类型
      if (this.courseTypesIndex > 1) {
        parm.courseType = this.courseTypes[this.courseTypesIndex].value
      }
      //投标类型
      if (this.bidTypesIndex == 2) {
        parm.IsCharge = false;
      } else if (this.bidTypesIndex == 3) {
        parm.IsCharge = true;
      }
      if (this.index == 1) {
        parm.CoursePackage = 1  // 是否课程包（0否1是）
      } else if (this.index == 2) {
        parm.courseTags = '推荐'  // 课程属性（首页推荐课程传“推荐”）
      }
      const res = await courseQueryCourseList(parm);
      if (res.success == true) {
        this.$set(this.list, "courses", res.response.data.slice(0, 12));
        this.$set(this.list, "types", this.type);
        this.total = res.response.dataCount;
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.course {
  .top {
    background-color: #fff;
    font-size: 14px;
    padding: 10px 0;
    .el-row {
      margin: 10px 0;
      .el-col {
        height: 36px;
        line-height: 36px;
        /deep/ .el-input {
          width: 220px;
          border-radius: 50%;
          border-color: #e4e4e4;
          .el-input__inner {
            color: #999999;
            height: 36px;
            line-height: 36px;
            border-radius: 50px;
          }
          .el-input-group__append {
            padding: 0 15px;
            border-radius: 0 15px 15px 0;
            .el-button {
              padding: 0 15px;
              border-color: #e4e4e4;
              border: 0;
            }
          }
        }
        /deep/ .search_input {
          .el-input__inner {
            border-radius: 50px 0 0 50px;
          }
        }
      }
      .types {
        width: 70px;
        text-align: center;
        float: left;
        margin-right: 25px;
        cursor: pointer;
        color: #333333;
        &:hover {
          background-color: #4887f9;
          border-radius: 4px;
          color: #fff;
        }
      }
      .active {
        background-color: #4887f9;
        border-radius: 4px;
        color: #fff;
      }
    }
  }
}
.couser_cascader {
  height: 36px;
  line-height: 36px;
  /deep/.el-input {
    .el-input__inner {
      border-radius: 4px !important;
    }
    .el-input__icon {
      line-height: 36px;
    }
  }
}
/deep/.el-cascader-panel {
  background-color: #fff;
}
.couser_cascader_hover {
  height: 36px;
  line-height: 36px;
  padding: 0 10px;
  width: 220px;
  border: 1px solid #dcdfe6;
  color: #999999;
  border-radius: 50px;
}
.home_row {
  display: flex;
  flex-wrap: wrap;
  .home_col {
    border: 1px solid #e7e7e7;
    height: 310px;
    width: 280px;
    margin-right: 26px;
    margin-bottom: 30px;
    background-color: #fff;
    &:nth-child(4n) {
      margin-right: 0;
    }
    .rig {
      padding: 0 20px;
    }
    .img_dom {
      width: 280px;
      height: 160px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .title {
      font-size: 20px;
      font-weight: bold;
      color: #333333;
      line-height: 1;
      margin: 18px 0 20px;
    }
    .per {
      display: flex;
      img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin: 0;
      }
      span {
        margin: 0 10px 0 3px;
      }
    }
    .price {
      display: flex;
      align-items: center;
      font-size: 22px;
      font-weight: bold;
      color: #fe4a4a;
      line-height: 1;
      span {
        font-size: 0.625rem;
        font-weight: normal;
      }
      div {
        &:nth-child(2) {
          margin-left: 8px;
          font-size: 12px;
          color: #b5b4b4;
          text-decoration: line-through;
        }
      }
    }
  }
}
/deep/.el-pagination {
  text-align: right;
  .el-pager {
    li {
      background-color: #fff;
      color: #999999;
    }
  }
}
</style>